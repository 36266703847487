<template>
  <v-dialog
    v-model="open"
    content-class="plans-list-filter font-inter"
    fullscreen
    persistent
  >
    <v-card class="relative">
      <div class="d-flex align-center justify-space-between mb-4">
        <h2 class="black--text">
          {{ $t('filters') }}
        </h2>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon color="black">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-expansion-panels
        v-model="configurationsPanel"
        flat
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0">
            {{ $t('configurations') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <search-field
              class="mt-2"
              :placeholder="$t('search')"
            />
            <div class="mt-2">
              <v-checkbox
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>Browsers</span>
                </template>
              </v-checkbox>
              <v-checkbox
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>Operating Systems</span>
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels
        v-model="priorityPanel"
        class="mt-6"
        flat
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0">
            {{ $t('priority') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mt-2">
              <v-checkbox
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>High</span>
                </template>
              </v-checkbox>
              <v-checkbox
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>Medium</span>
                </template>
              </v-checkbox>
              <v-checkbox
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>Low</span>
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels
        v-model="statusPanel"
        class="mt-6"
        flat
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0">
            {{ $t('status') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mt-2">
              <v-checkbox
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>New</span>
                </template>
              </v-checkbox>
              <v-checkbox
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>In progress</span>
                </template>
              </v-checkbox>
              <v-checkbox
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>Completed</span>
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="mt-6 text-start">
        <div>{{ $t('numberOfTestRuns') }}</div>
        <v-range-slider
          v-model="testrun"
          class="mt-4"
          style="height: 50px"
          color="blue"
        />
        <div class="d-flex align-center">
          <v-text-field
            v-model="testrun[0]"
            class="mr-0 custom_input"
            color="blue"
            hide-details="auto"
            filled
            dense
            height="40"
          />
          <v-icon class="mx-4">
            mdi-minus
          </v-icon>
          <v-text-field
            v-model="testrun[1]"
            class="mr-0 custom_input"
            color="blue"
            hide-details="auto"
            filled
            dense
            height="40"
          />
        </div>
      </div>
      <div class="mt-6 text-start">
        <div>{{ $t('creationDate') }}</div>
        <div class="mt-2 d-flex align-center">
          <v-menu
            v-model="creationDateMenu1"
            :close-on-content-click="false"
            full-width
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mr-0 custom_input"
                color="blue"
                clearable
                readonly
                filled
                dense
                height="40"
                hide-details="auto"
                :value="creationDate1"
                v-on="on"
              >
                <template v-slot:prepend>
                  <v-icon color="blue">
                    mdi-calendar-blank-outline
                  </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="creationDate1"
              @change="creationDateMenu1 = false"
            />
          </v-menu>
          <v-icon class="mx-4">
            mdi-minus
          </v-icon>
          <v-menu
            v-model="creationDateMenu2"
            :close-on-content-click="false"
            full-width
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mr-0 custom_input"
                color="blue"
                clearable
                readonly
                filled
                dense
                height="40"
                hide-details="auto"
                :value="creationDate2"
                v-on="on"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="blue">
                    mdi-calendar-blank-outline
                  </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="creationDate2"
              @change="creationDateMenu2 = false"
            />
          </v-menu>
        </div>
      </div>
      <v-expansion-panels
        v-model="tagsPanel"
        class="mt-6"
        flat
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0">
            {{ $t('tags') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <search-field
              class="mt-2"
              :placeholder="$t('search')"
            />
            <div class="mt-2">
              <v-checkbox
                v-for="(tag, index) of tags"
                :key="index"
                class="py-2"
                color="blue"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span>{{ tag.title }}</span>
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="mt-6 text-start">
        <div>{{ $t('progress') }}</div>
        <v-range-slider
          v-model="progress"
          class="mt-4"
          style="height: 50px"
          color="blue"
        />
        <div class="d-flex align-center">
          <v-text-field
            v-model="progress[0]"
            class="mr-0 custom_input"
            color="blue"
            hide-details="auto"
            filled
            dense
            height="40"
            :value="`${progress[0]}%`"
          />
          <v-icon class="mx-4">
            mdi-minus
          </v-icon>
          <v-text-field
            class="mr-0 custom_input"
            color="blue"
            hide-details="auto"
            filled
            dense
            height="40"
            :value="`${progress[1]}%`"
          />
        </div>
      </div>
      <div class="actions-container absolute d-flex justify-end mt-auto py-8 b-0">
        <v-btn
          color="gray-100"
          class="mr-4 text-capitalize"
          depressed
          @click="$emit('close')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue"
          class="text-capitalize"
          depressed
          dark
          @click="$emit('apply')"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { roles, projects, tags } from '@/constants/data.js';
import SearchField from '../../../../../components/Form/SearchField.vue';
export default {
  name: 'PlansListFilter',
  components: { SearchField },

  props: ['open'],
  data() {
    return {
      dialog: true,
      configurationsPanel: [0],
      priorityPanel: [0],
      statusPanel: [0],
      tagsPanel: [0],
      testrun: [0, 100],
      creationDateMenu1: false,
      creationDateMenu2: false,
      creationDate1: new Date().toISOString().substr(0, 10),
      creationDate2: new Date().toISOString().substr(0, 10),
      tags,
      progress: [0, 100],
    };
  },
  mounted() {
    this.clearAll();
  },
  methods: {
    apply() {
      const selectedRoles = this.roles.filter((role) => role.selected);
      const selectedProjects = this.projects.filter((project) => project.selected);
      const selectedTags = this.tags.filter((tag) => tag.selected);
      const filters = {
        roles: selectedRoles,
        projects: selectedProjects,
        tags: selectedTags,
      };
      this.$emit('filters', filters);
      this.dialog = false;
    },
    clearAll() {

    },
  },
};
</script>

<style lang="scss">
.plans-list-filter {
  width: 485px !important;
}
</style>

<style lang="scss" scoped>
.v-dialog--fullscreen > .v-card {
  padding: 32px !important;
  padding-bottom: 104px !important;
}

.v-input {
  margin-top: 0;
  padding-top: 0;
}

.v-input--dense {
  margin-top: 0;
}

.v-expansion-panel-header {
  padding: 8px 0;
  min-height: unset;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
  padding-left: 0;
  padding-right: 0;
}

.actions-container {
  bottom: 0;
  left: 32px;
  right: 32px;

  button {
    flex: 1;
  }
}
</style>